import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

import Resources from "app/templates/resources";

const ResourcesPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentJson(template: { eq: "resources" }) {
        ...ResourcesPage
      }
    }
  `);

  return <Resources {..._get(data, "contentJson")} />;
};

export default ResourcesPage;
