import React, { useState, useRef, useEffect } from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import Btn from "app/components/btn";
import Hero, { heroField } from "app/components/hero";
import IntroBlock from "app/components/intro-block";
import Layout from "app/components/layout";
import SplitBox from "app/components/split-box";
import SEOMeta, { seoField } from "app/components/seo";

import * as S from "app/styles/shared";

const Mediakit = ({ text, cta }) => (
  <S.Tagline pushBot>
    {text}{" "}
    {cta && !!cta.url && <Btn css={S.halfPadLeft} {...cta} variant="link" />}
  </S.Tagline>
);

const tabStyle = css(`
  appearance: none;
  font-size: 1.75rem;
  background: none;
  cursor: pointer;
  text-align: start;
  border: 0;

  &[aria-selected="true"] {
    text-decoration: underline;
  }
`);

const ToolKitTabs = ({ toolkits }) => {
  const [active, setActive] = useState(0);
  const tablistRef = useRef(null);

  useEffect(() => {
    if (tablistRef.current) {
      const activeTab = tablistRef.current.querySelector(
        `[role="tab"][aria-selected="true"]`
      );
      activeTab && activeTab.focus();
    }
  }, [active]);

  if (!Array.isArray(toolkits) || toolkits.length < 1) {
    return null;
  }

  return (
    <>
      <S.Container
        css={[S.innerContainer, css(`display:flex; flex-wrap:wrap; gap:2rem;`)]}
        ref={tablistRef}
        role="tablist"
        onKeyDown={(e) => {
          if (e.key === "ArrowRight") {
            setActive((active + 1) % toolkits.length);
          } else if (e.key === "ArrowLeft") {
            setActive((active - 1 + toolkits.length) % toolkits.length);
          }
        }}
      >
        {toolkits.map(({ tabTitle }, idx) => (
          <button
            key={`toolkit-${idx}`}
            role="tab"
            css={tabStyle}
            aria-selected={idx === active ? "true" : "false"}
            aria-controls={`toolkit-panel-${idx}`}
            tabIndex={idx === active ? 0 : -1}
            id={`toolkit-${idx}`}
            onClick={() => setActive(idx)}
            onFocus={() => setActive(idx)}
          >
            {tabTitle}
          </button>
        ))}
      </S.Container>

      {toolkits.map(({ btn, ...toolkit }, idx) => (
        <div
          role="tabpanel"
          key={`toolkit-panel-${idx}`}
          id={`toolkit-panel-${idx}`}
          aria-labelledby={`toolkit-${idx}`}
          hidden={active !== idx}
        >
          <SplitBox
            items={[
              {
                ...toolkit,
                btns: toolkit.btns?.map((btn) => ({
                  ...btn,
                  track_action: "resource_toolkit_download",
                  track_cat: "toolkits",
                })),
              },
            ]}
            circular={false}
          />
        </div>
      ))}
    </>
  );
};

const PageResources = ({ title, hero, intro, mediakit, ...props }) => {
  return (
    <Layout>
      <SEOMeta title={title} {..._get(props, "seo")} />
      <div>
        <IntroBlock title={title} intro={intro} />
        <Hero fade {...hero} css={S.marginXtraBot} />
        <ToolKitTabs toolkits={_get(props, "toolkits", [])} />
        <Mediakit {...mediakit} />
      </div>
    </Layout>
  );
};

export default PageResources;

export const resourcesFragment = graphql`
  fragment ResourcesPage on ContentJson {
    ...SEO
    title
    intro
    ...Hero
    toolkits {
      ...SplitBox
      tabTitle
    }
    mediakit {
      cta {
        ...ButtonFields
      }
      text
    }
  }
`;

export const resourcesFields = [
  seoField,
  {
    label: "Title",
    name: "title",
    widget: "string",
  },
  {
    label: "Intro",
    name: "intro",
    widget: "markdown",
  },
  heroField,
  {
    label: "Toolkits",
    name: "toolkits",
    widget: "list",
    fields: [
      {
        label: "Tab Title",
        name: "tabTitle",
        widget: "string",
      },
      {
        label: "Title",
        name: "title",
        widget: "string",
      },
      {
        label: "Text",
        name: "text",
        widget: "text",
      },
      {
        label: "Download",
        name: "btns",
        widget: "list",
        fields: [
          {
            label: "Text",
            name: "label",
            widget: "string",
          },
          {
            label: "Link",
            name: "file",
            widget: "string",
          },
          {
            label: "New Window?",
            name: "new_window",
            widget: "boolean",
            default: true,
            required: false,
          },
        ],
      },
      {
        label: "Image",
        name: "img",
        widget: "image",
      },
    ],
  },
  {
    label: "MediaKit",
    name: "mediakit",
    widget: "object",
    fields: [
      {
        label: "Text",
        name: "text",
        widget: "string",
      },
      {
        label: "Download",
        name: "cta",
        widget: "object",
        fields: [
          {
            label: "Text",
            name: "label",
            widget: "string",
          },
          {
            label: "Link",
            name: "url",
            widget: "string",
          },
          {
            label: "New Window?",
            name: "new_window",
            widget: "boolean",
            default: true,
            required: false,
          },
        ],
      },
    ],
  },
];
